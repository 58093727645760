import styled from "styled-components"

export const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-flow: row;
  justify-content: space-evenly;
  align-items: stretch;
  z-index: 1000;
  @media (max-width: 767px) {
    flex-wrap: no-wrap;
    flex-flow: column;
    justify-content: space-evenly;
    align-items: center;
    height: 100%;
  }
`

export const CardWrapper = styled.div`
  width: 25%;
  min-width: 350px;
  background: rgb(9, 191, 236);
  background: linear-gradient(
    0deg,
    rgba(9, 191, 236, 1) 0%,
    rgba(11, 174, 231, 1) 100%
  );
  border-radius: 10px;
  padding: 3px;
  box-shadow: 0px 0px 25px -2px rgba(0, 255, 255, 0.45);

  @media (max-width: 767px) {
    align-items: center;
    border: none;
    box-shadow: none;
    margin-top: 1rem;
    width: auto;
    min-width: none;
  }
`

export const Card = styled.div`
  height: 100%;
  background-color: #fff;
  border: 1px solid #fff;
  border-radius: 40px;
  padding: 3rem 1rem;
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: space-between;

  @media (max-width: 767px) {
    padding: 0.5rem;
  }
`

export const Title = styled.h5`
  font-size: 24px;
  line-height: 32px;
  user-select: none;

  @media (max-width: 767px) {
    margin: 0.5rem;
  }
`

export const Text = styled.p`
  font-size: 18px;
  line-height: 30px;
  text-align: center;
  margin-bottom: 2rem;

  @media (max-width: 767px) {
    margin-bottom: 1rem;
  }
`
