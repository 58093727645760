import React from "react"
import { Link } from "gatsby"
import { ModalRoutingContext } from "gatsby-plugin-modal-routing"
import { navigate, graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"

import CtaButton from "@common/CtaButton"

import { Card, CardWrapper, Container, Title, Text } from "./style"
import { ReactComponent as CloseIcon } from "@static/icons/close.svg"

const Modal = () => {
  const data = useStaticQuery(graphql`
    query {
      mockupSoftware: file(
        sourceInstanceName: { eq: "art" }
        name: { eq: "modal_mockup_1" }
      ) {
        childImageSharp {
          fluid(maxWidth: 500) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      mockupDevices: file(
        sourceInstanceName: { eq: "art" }
        name: { eq: "sign_details_mockup_2" }
      ) {
        childImageSharp {
          fluid(maxWidth: 500) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)

  return (
    <ModalRoutingContext.Consumer>
      {({ modal }) => (
        <div>
          {modal && (
            <CloseIcon
              style={{ width: "25px", cursor: "pointer" }}
              onClick={() => navigate(`/`, { state: { noScroll: true } })}
            />
          )}
          <Container>
            <CardWrapper>
              <Card>
                <Img
                  className="ModalImg"
                  fluid={data.mockupSoftware.childImageSharp.fluid}
                  style={{ width: "100%" }}
                />
                <Title>SmartHospital</Title>
                <Text>
                  Our solution is focused on offering efficient device tracking
                  and organization
                </Text>
                <Link
                  to="/smart-hospital/"
                  state={{
                    modal: false,
                    noScroll: false,
                  }}
                >
                  <CtaButton text="Learn more" small />
                </Link>
              </Card>
            </CardWrapper>
            <CardWrapper>
              <Card>
                <Img
                  className="ModalImg"
                  fluid={data.mockupDevices.childImageSharp.fluid}
                  style={{ width: "100%" }}
                />
                <Title>Bio Sign</Title>
                <Text>
                  Our solution is focused on offering efficient device tracking
                  and organization
                </Text>
                <Link
                  to="/bio-sign/"
                  state={{
                    modal: false,
                    noScroll: false,
                  }}
                >
                  <CtaButton text="Learn more" small />
                </Link>
              </Card>
            </CardWrapper>
          </Container>
        </div>
      )}
    </ModalRoutingContext.Consumer>
  )
}

export default Modal
